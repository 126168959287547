import React, { useState } from 'react';
import {IStateView} from './Dashboard';
import { UiTextField } from '../shared/components/fields';
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'ts-debounce';
import { ButtonGroup, Button } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AddIcon from '@material-ui/icons/Add';
import ReplyIcon from '@material-ui/icons/Reply';
import { ModalFilters } from './components/filters/ModalFilter';
import {Reference} from '../shared/services/referencesService'
import {IModalFilter} from './interface'
import { DashboardService } from '../shared/services/dashboardService';
import * as XLSX from 'xlsx';
import {UserGroup, UserRoles} from '../shared/models/enums';
import { getMappingRoles } from '../shared/models/records';
import LocalStorageTokenService from '../shared/helpers/SessionStorageTokenService';
import { LoggingServie } from '../shared/services/LoggingService';
import {insuranceRoles} from "../shared/config/securityConcept";
import {useHistory} from "react-router";
import UiSplitButton from "../shared/components/ui/UiSplitButton";

type IPropsDashboardActions = {
    setSearchQuery(searchQuery: string) : void
    setModalFilter(modalFilterValue : IModalFilter) : void
    setView(view: IStateView) : void
    modalFilter : IModalFilter
    view: IStateView
    searchQuery: string
    coutriesRef : Reference[]
    riskLevelRef : Reference[],
    eventStatusListRef : Reference[],
    maisonRef : Reference[]
}
export const DashboardActions = (props: IPropsDashboardActions) => {
    const idrole = getMappingRoles(LocalStorageTokenService.getUserRoleToken());
    const history = useHistory();
    const exportActions = [{ name: "Export", label: "Export" }, { name: "Summary export", label: "Summary export" }];

    const updateSearchQuery = (value: string) => {
        props.setSearchQuery(value)
    }
    const handleSearchChange = (event:any) => {
        updateSearchQuery(event.target.value)
    }
    const [ modalFilterActive, setModalFilterActive] = useState<boolean>(false)
    const [ exportModalFilterActive, setExportModalFilterActive] = useState<boolean>(false)

    const handleCloseModal = () =>{
        setModalFilterActive(false)
    }

    const handleOpenModal = () =>{
        setModalFilterActive(true)
    }

    const isCurrency = (colName: string) : boolean => {
        const matchingTerm = ["value", "Value", "Amount", "amount"]
        for (let term of matchingTerm) {
            if (colName.includes(term)) return true;
        }
        return false;
    }

    const extractHeaders = (ws: XLSX.WorkSheet) : string[] => {
        const headers = []
        const columnCount = XLSX.utils.decode_range(ws['!ref']!).e.c + 1
        for (let i = 0; i < columnCount; ++i) {
            headers[i] = ws[`${XLSX.utils.encode_col(i)}1`].v
        }
        return headers
    }

    function formatColumn(worksheet: XLSX.WorkSheet, col : number, fmt : string) {
        const range = XLSX.utils.decode_range(worksheet['!ref']!)
        // note: range.s.r + 1 skips the header row
        for (let row = range.s.r + 1; row <= range.e.r; ++row) {
            const ref = XLSX.utils.encode_cell({ r: row, c: col })
            if (worksheet[ref] && worksheet[ref].t === 'n') {
                worksheet[ref].z = fmt
            }
        }
    }

    const formatCurrency = (ws: XLSX.WorkSheet) => {
        const headers = extractHeaders(ws)
        const indexCurrencyCol = headers.map((header, index) => {
            return isCurrency(header) ?  index : -1;
        }).filter(index => index >= 0)
        console.warn("indexCurr", indexCurrencyCol)
        const currencyFormat = '€0.00'
        for (let col of indexCurrencyCol) {
            formatColumn(ws, col, currencyFormat)
        }
    }

    const exportData = (event: any, actionName: any) => {
        const exportFilters = props.modalFilter;
        if (actionName == "Summary export") {
            exportFilters.isSummary = true;
        } else {
            exportFilters.isSummary = false;
        }
        DashboardService.getEventDeclarationExportData(exportFilters).subscribe(response => {
            const data = response.data.model;
            console.warn("data", data)
            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
            formatCurrency(worksheet)
            const workbook: XLSX.WorkBook = { Sheets: { 'Events': worksheet }, SheetNames: ['Events'] };
            const filename: string = 'EventDeclarationExportList.xlsx';
            XLSX.writeFile(workbook, filename);
        }, error => {
            LoggingServie.LogError(error, '');
        })
    }

    const userCanSeeNewEvent = () => {
        return !insuranceRoles.includes(LocalStorageTokenService.getUserRoleToken() as UserRoles);
    }

    return(
        <div className="dashboard-actions">
            <div className="dashboard-actions__item">
                <ButtonGroup disableElevation={true}>
                    <Button
                        size="medium"
                        color="primary"
                        variant={props.view==='list' ? "contained" : 'outlined'}
                        startIcon={<ListIcon />}
                        onClick={()=> {
                            props.setView('list');
                            sessionStorage.setItem('view', 'list');
                        }}
                    >
                        List view
                    </Button>
                    <Button
                        id="calendarViewBtn"
                        size="medium"
                        color="primary"
                        variant={props.view==='calendar' ? "contained" : 'outlined'}
                        startIcon={<DateRangeIcon />}
                        onClick={()=> {
                            props.setView('calendar');
                            sessionStorage.setItem('view', 'calendar');
                        }}
                    >
                        Calendar
                    </Button>
                </ButtonGroup>
            </div>
            <div className="dashboard-actions__item dashboard-actions__item--search">
                <UiTextField
                    label="search"
                    fullWidth={false}
                    onChange={handleSearchChange}
                    prefix={<SearchIcon />}
                    value={props.searchQuery}
                />
            </div>
            <div className="dashboard-actions__item">
                <Button id="addFiltersBtn" color="secondary" variant="outlined" startIcon={<AddIcon />} onClick={handleOpenModal}>Filters</Button>
                <ModalFilters
                    open={modalFilterActive}
                    isListViewFilter={true}
                    onClose={handleCloseModal}
                    onApply={props.setModalFilter}
                    modalFilter={props.modalFilter}
                    coutriesRef={props.coutriesRef}
                    riskLevelRef={props.riskLevelRef}
                    eventStatusListRef={props.eventStatusListRef}
                    maisonRef={props.maisonRef}
                />
            </div>
            {(
                <UiSplitButton
                    actions={exportActions}
                    default={0}
                    onClick={(event: any, actionName: any) => exportData(event, actionName)}
                />

            )}
            <div className="dashboard-actions__item dashboard-actions__item--export">
                {userCanSeeNewEvent() && (
                    <Button
                        id="newEventBtn"
                        color="secondary"
                        variant="outlined"
                        startIcon={<AddIcon/>}
                        onClick={() => history.push("/dashboard/event/new")}>
                        New Event
                    </Button>
                )}
            </div>
        </div>
    )
}