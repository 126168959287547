import React, { useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import UiTooltipLabel from './UiTooltipLabel';
import UiFieldWrapper from './UiFieldWrapper';
import UiBaseField from './UiBaseField';
import { InputLabel, FormHelperText, Button } from '@material-ui/core';
import { getFieldData } from './UiFieldHelpers';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';


type IPropsUiSwitchField = UiBaseField & {
    uncheckWarning?: boolean,
    fieldtochange?: string
}

export const UiSwitchField = (props: IPropsUiSwitchField) => {
    const [open, setOpen] = React.useState(false);
    const {error, name, value, onChange} = getFieldData(props);
    const label = UiTooltipLabel({ label: props.label, tooltip: props.tooltip })
    const [checked, setChecked] = useState(value ? value : false);
    useEffect(() => setChecked(value ? value : false), [value]);

    const { form, fieldToChange } = props;
    const handleChange = (event: any) =>{
        if(checked && props.uncheckWarning && form?.getFieldProps(fieldToChange).value){
            setOpen(true);
        }else{
            if(fieldToChange) {
                setField(fieldToChange);
            }
            setChecked(!checked)
            onChange(event)
        }
    }

    const setField = (fieldname: string) => {
        if(Array.isArray(form?.values[fieldname]))
            form?.setFieldValue(fieldname, []);
        else
            form?.setFieldValue(fieldname, '');
    }

    const handleClose=()=>{
        setOpen(false);
    }

    const handleConfirm=()=>{
        if(fieldToChange) {
            setField(fieldToChange)
        }
        setChecked(false);
        setOpen(false);
        form?.setFieldValue(props.field.name, false);
    }

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure to diselect this
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button name="noBtn" onClick={handleClose} color="primary">
                        No
                    </Button>
                    <Button data-yes-button id="yesDialogBtn" onClick={handleConfirm} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <UiFieldWrapper name="ui-switch-field" hideTooltip={true} {...props}>
                <InputLabel error={error.hasError} className="ui-switch-field__label ui-field__label">
                    {label}
                    {error.hasError && <FormHelperText error={error.hasError}>{error.errorMessage}</FormHelperText> }
                </InputLabel>
                <div className="ui-switch-field__field ui-field__field">
                    <Switch
                        id={props.id}
                        checked={checked}
                        disabled={props.disabled}
                        name={name}
                        onChange={handleChange}
                    />
                </div>
            </UiFieldWrapper>
        </React.Fragment>
    )
}
export default UiSwitchField