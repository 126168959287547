import React, { ReactNode, FC } from "react"
import Modal from '@material-ui/core/Modal';
import UiBox from "../UiBox";
import CloseIcon from '@material-ui/icons/Close';

export type ModalSize = 'sm' | 'md' | 'lg' | 'xl'

export type IPropsUiModalBase = {
    ref?: React.Ref<any>;
    open: boolean;
    /**
     * Callback fired when the component requests to be closed.
     *
     * @param {object} event The event source of the callback.
     * @param {string} reason Can be: `"escapeKeyDown"`, `"backdropClick"`, `"closeIconClick"`.
     */
    onClose?(event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'closeIconClick' | 'cancelBtnClick' | 'applyFilter' | 'clearFilter'): void;
    footerButtons?: ReactNode[];
    className?: string
}
type IPropsUiModal = IPropsUiModalBase & {
    id?: string
    title: string
    size?: ModalSize
}
export const UiModal : FC<IPropsUiModal> = (props) => {
    const classes = `ui-modal__content ui-modal__content--${props.size ?? 'md'}`;
    
    const handleClickClose = (event:any) => {
        if(props.onClose){
            props.onClose(event, 'closeIconClick')
        }
    }
    const renderFooter = () => {
        if(props.footerButtons && props.footerButtons.length){
            return <div className="ui-modal__footer ui-modal__footer--buttons">{props.footerButtons.map((btn,index)=> <div key={index}>{btn}</div>)}</div>
        }
        return "";
    }
    return(
    <div onClick={(e : any) => {e.stopPropagation();}}>
        <Modal
            ref={props.ref}
            open={props.open}
            onClose={props.onClose}
            className={props.className}
        >
           <div id={props.id} className="ui-modal">
               <div className={classes}>
                <UiBox
                        title={props.title}
                        actions={<button onClick={handleClickClose} className="ui-modal__close"><CloseIcon fontSize="large" /></button>}
                    >
                        <>
                            {props.children}
                        {renderFooter()}
                        </>
                </UiBox>
                </div>
            </div>
        </Modal>
    </div>
    )
}