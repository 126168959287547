import { IPropsDashboardBase } from '../../dashboard/interface';
import { RiskTagsProps, ApiResponse } from '../models/interfaces';
import { urlPartialGetDashboard, urlPartialGetEventDeclarationExportData } from '../config/GlobalAppConfig';
import {IModalFilter} from '../../dashboard/interface';
import {cloneDeep} from 'lodash';
import { map } from 'rxjs/operators';
import { isToShowExclamationPoint } from '../helpers/HelpersFunc';
import { AxiosRequestConfig } from 'axios';
import CustomAxios, { ApiCallingMethods } from '../helpers/AxiosHelper';
import SessionStorageTokenService from '../helpers/SessionStorageTokenService';
import { getMappingRoles } from '../models/records';

interface DasboardReq extends IPropsDashboardBase, RiskTagsProps {}

// interface DashboardItem {
//     itemContent: string;
//     eventName: string;
//     leadbrandName: string;
//     countriesContent: string;
//     romDate: Date;
//     toDate: Date;
//     locationName: string;
//     estimatedTotalValue: number;
//     countryLevelRiskContent: string;
//     riskTags: RiskTagsProps;
//     riskLevelContent: string;
//     idTask: number;
//     idFlow: number;
//     idNextTask: number;
//     taskLabel: string;
// }


const dasboardReq: DasboardReq = {
    countriesContent: null,
    estimatedTotalValue: 0,
    countryLevelRiskContent: null,
    eventName: null,
    fromDate: null,
    toDate: null,
    isHeritage: false,
    isStrategic: false,
    leadbrandName: null,
    locationName: null,
    riskLevelContent: null,
    idEventFlow:null,
    idTask: null,
    idFlow : null,
    idNextTask: null,
    taskLabel: null
}

export class DashboardService {
    static getDashboard() {
        const userid = getMappingRoles(SessionStorageTokenService.getUserRoleToken());
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: urlPartialGetDashboard + '/' + userid,
            data: dasboardReq
        };
        return CustomAxios.request(options).pipe(map((response: ApiResponse<any>) => {
            response.data.model.dashboardListEntity.map((item: any) => {
                item.showExclamationPoint = isToShowExclamationPoint(item);
                item.status = item.itemContent;
                item.riskTags = {
                   isVIP: item.isVIP,
                   isStrategic: item.isStrategic,
                   isPublic: item.isPublic,
                   isHeritage: item.isHeritage
                }
    
                delete item.isVIP;
                delete item.isStrategic;
                delete item.isPublic;
                delete item.isHeritage;
                delete item.itemContent;
    
                return item;
            })
            return response.data.model;
        }));
    }

    static getEventDeclarationExportData(filter : IModalFilter) {
        const options : AxiosRequestConfig = {
            method: ApiCallingMethods.post,
            url: urlPartialGetEventDeclarationExportData,
            data: this.DTODashboarModaldFilter_to_DashboarModaldFilterBackEnd(filter)
        };
        return CustomAxios.request(options);
    }

    static DTODashboarModaldFilter_to_DashboarModaldFilterBackEnd(filter : IModalFilter){
        // Use cloneDeep() here because if why use normale assignement(shallow copy),
        // when we use delete, fields are deleted in both variable => filter and backendFilter.
        // But if we delete filter's fields, we break dashboard modal filter.
        //So we use deep copy to avoir link beetween this two variable
        let backendFilter : any = cloneDeep(filter)
        backendFilter.isHeritage = filter.riskTags.heritage
        backendFilter.isPublic = filter.riskTags.public
        backendFilter.isStrategic = filter.riskTags.strategic
        backendFilter.isVip = filter.riskTags.vip
        backendFilter.fromDate = filter.from
        backendFilter.toDate = filter.to

        delete  backendFilter.eventToWatch
        delete  backendFilter.riskTags
        delete  backendFilter.from
        delete  backendFilter.to
        
        return backendFilter
    }
}