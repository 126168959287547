import React, { useEffect } from 'react'
import { UiModal, IPropsUiModalBase } from '../../../shared/components/ui/UiModal/UiModal'
import {
    UiAutocompleteField,
    UiSwitchField,
    UiDateField,
    UiAmountField
} from "../../../shared/components/fields";
import { Button } from '@material-ui/core';
import { Formik, Field, FormikProps } from 'formik';
import { Reference } from '../../../shared/services/referencesService'
import { IModalFilter } from '../../interface'

const filterInitialValue: IModalFilter = {
    riskAssessment: false,
    securityConcept: false,
    eventToWatch: false,
    isSummary: false,
    status: [],
    from: "",
    to: "",
    levelRisk: [],
    country: [],
    riskTags: {
        heritage: false,
        public: false,
        strategic: false,
        vip: false
    },
    leadBrandName: [],
    minEstimatedValue: undefined,
    maxEstimatedValue: undefined,
    reportedDateFrom: "",
    reportedDateTo: "",
    isNotCancelled: true,
    isNotClosed: true,
    kpiFilter: ""
}

type IPropsUiModalBrocker = IPropsUiModalBase & {
    onApply(filters: any): void
    modalFilter : IModalFilter | undefined
    coutriesRef: Reference[]
    riskLevelRef: Reference[]
    eventStatusListRef: Reference[]
    maisonRef: Reference[]
    isListViewFilter: boolean
}

export class ModalFilters extends React.Component<IPropsUiModalBrocker, any> {

    handleCancel = (values: any, actions: any) => {
        this.props.onApply(undefined)
        if(this.props.onClose) this.props.onClose({},'clearFilter')
    }

    Submit = (values: any, actions: any) => {
        this.props.onApply(values)
        if(this.props.onClose) this.props.onClose({},'applyFilter')
    }

     render(){
         return( <UiModal
            title={this.props.isListViewFilter ? "Filters":"Export Filters"}
            open={this.props.open}
            onClose={this.props.onClose}
        >
            <div className="row">
                <Formik
                    initialValues={filterInitialValue}
                    onSubmit={this.Submit}
                    onReset={this.handleCancel}
                >
                    {({
                        handleSubmit,
                        handleReset,
                        initialValues,
                        setFieldValue
                    }: FormikProps<any>) => {
                        useEffect(() => {
                            //TODO try find better way for "as any" in setFieldValue
                            if(this.props.modalFilter){
                                Object.keys(initialValues).forEach(key => {
                                    setFieldValue(key, (this.props.modalFilter as any)[key], false)
                                 })
                            }

                         }, [initialValues, setFieldValue])
                        return (
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="row--flex">
                                        {this.props.isListViewFilter && <Field component={UiSwitchField} name="eventToWatch" label="Event to watch" />}
                                        <Field component={UiSwitchField} name="securityConcept" label="Security Concept" />
                                        <Field component={UiSwitchField} name="riskAssessment" label="Risk Assessment" />
                                        <Field component={UiSwitchField} name="isNotCancelled" label="Not Cancelled" />
                                        <Field component={UiSwitchField} name="isNotClosed" label="Not Closed" />
                                    </div>
                                    <Field
                                        id="statusAutocomplete"
                                        component={UiAutocompleteField}
                                        options={this.props.eventStatusListRef ? this.props.eventStatusListRef : []}
                                        name="status"
                                        label="Status"
                                        multiple={true}
                                    />
                                    <Field component={UiDateField} column="half" name="from" label="From" />
                                    <Field component={UiDateField} column="half" name="to" label="To" />
                                    <Field
                                        id={"eventRiskAutocomplete"}
                                        component={UiAutocompleteField}
                                        options={this.props.riskLevelRef ? this.props.riskLevelRef : []}
                                        name="levelRisk"
                                        label="Event Risk Level"
                                        multiple={true}
                                    />
                                    <Field
                                        component={UiAutocompleteField}
                                        options={this.props.coutriesRef ? this.props.coutriesRef : []}
                                        name="country"
                                        label="Country"
                                        multiple={true}
                                    />
                                    <Field
                                        component={UiAutocompleteField}
                                        options={this.props.maisonRef ? this.props.maisonRef : []}
                                        name="leadBrandName"
                                        label="Maison"
                                        multiple={true}
                                    />
                                    <Field component={UiDateField} column="half" name="reportedDateFrom" label="Reported Date From" />
                                    <Field component={UiDateField} column="half" name="reportedDateTo" label="Reported Date To" />
                                    <Field component={UiAmountField} min={0} column="half" name="minEstimatedValue" label="Min Estimated Value" type="number" />
                                    <Field component={UiAmountField} min={0} column="half" name="maxEstimatedValue" label="Max Estimated Value" type="number" />
                                    <div className="row__bottom">
                                        <Field component={UiSwitchField} name="riskTags.strategic" label="Open displays" />
                                        <Field component={UiSwitchField} name="riskTags.heritage" label="Heritage pieces" />
                                        {!this.props.isListViewFilter  && <Field component={UiSwitchField} name="isSummary" label="Summary export" />}
                                    </div>
                                </div>
                                <div className="ui-modal__footer ui-modal__footer--buttons">
                                    {this.props.isListViewFilter &&<Button onClick={handleReset} variant="outlined" color="primary" key="cancel"  id="modal-filter-cancel">Clear Filter</Button>}
                                    <Button onClick={() => { handleSubmit()}} variant="contained" color="primary" key="apply" id="modal-filter-submit">{this.props.isListViewFilter ? "Apply Filter":"Export"}</Button>
                                </div>
                            </form>
                        )
                    }

                    }
                </Formik>
            </div>
        </UiModal>
        )
     }

}