import {get} from 'lodash';
import { Accessor, Primitive } from './types';
import {isNullOrUndefined} from "util";


export function accessValue(item: object, accessor:Accessor) : Primitive {
    if(typeof accessor === "string"){
        return get(item, accessor)
    }else{
        return accessor(item);
    }
}

const getStringComparator = (a:string, b:string, asc: boolean) => {
    if(asc){
        return a.localeCompare(b);
    }else{
        return b.localeCompare(a);
    }
}
const getNumericComparator = ( a:number, b:number, asc: boolean) => {
    if(asc){
        return a - b
    }else{
        return b - a
    }
}


export const getComparator = (accessor: Accessor,  a:any, b:any, asc:boolean) =>{
    const first = accessValue(a, accessor)
    const second = accessValue(b, accessor)

    if(typeof first === 'undefined' || first === undefined || first === null){
        return 1
    }
    if(typeof second === 'undefined' || second === undefined || second === null){
        return -1
    }
    if(typeof first !== typeof second){
        return -1;
    }
    switch(typeof first){
        case 'number':
        case 'boolean':
           return getNumericComparator(first as number, second as number, asc) 
        case 'string':
            return getStringComparator(first, second as string, asc)
        case 'object':
            return 1;
        default:
            return 1;
    }
   
}