import axios from "axios-observable";
import { AxiosRequestConfig } from 'axios'
 import { serverUrlBase } from '../config/GlobalAppConfig'
import { NotificationType } from "../models/enums";
import {history} from "../../routes/RouterHistory";

export enum ApiCallingMethods {
    get = 'GET',
    delete = 'DELETE',
    head = 'HEAD',
    options = 'OPTIONS',
    post = 'POST',
    put = 'PUT',
    patch = 'PATCH',
    link = 'LINK',
    unlink = 'UNLINK'
}

export const HTTP_STATUS_UNAUTHORIZED = 401;

class CustomAxios {
    instance: axios;
    // LocalstorageService
    callBack: ((params : any,type : NotificationType) => void) | null;
    private accessToken = "";

    setAccessToken(accessToken: string){
        this.accessToken = accessToken
    }
    
    constructor(axiosConfig : AxiosRequestConfig) {
        this.instance = axios.create(axiosConfig);
        this.callBack = null
        // Interceptor for request
        this.instance.interceptors.request.use(
            config => {
                const token = this.accessToken;
                if (token) {
                    config.headers['Authorization'] = 'Bearer ' + token;
                }
                if(axiosConfig.responseType !== undefined || axiosConfig.responseType !== null || axiosConfig.responseType !== ""){
                    config.headers['Content-Type'] = axiosConfig.responseType?.valueOf();
                }
                else{
                    config.headers['Content-Type'] = 'application/json';
                }
                return config;
            },
            error => {
                Promise.reject(error)
            });

        // Interceptor for response
        this.instance.interceptors.response.use((response) => {
            if (this.callBack) this.callBack(response,NotificationType.success);
            return response;
        }, error => {
            if (this.callBack) this.callBack(error, NotificationType.error);
            const {status} = error.response;
            if (status === HTTP_STATUS_UNAUTHORIZED) {

                if (!window.location.href.includes('/Error')) {
                    history.push('/Error', {detail: HTTP_STATUS_UNAUTHORIZED});
                }
            }

            return Promise.reject(error.response);
        });
    }

    request = (options: AxiosRequestConfig, callBack: ((resp : any, type : NotificationType) => void) | null = null ) => {
        this.callBack = callBack;
        return this.instance.request(options);
    };
}

export default new CustomAxios({baseURL : serverUrlBase});