import React, {useState, useEffect, useRef} from 'react'
import { Field } from 'formik'
import * as Yup from 'yup'
import { IEventRisksLevel } from '../../interface'
import {
   UiSelectField,
   UiSwitchField,
   UiAmountField,
   UiNumberField
} from "../../../shared/components/fields/";
import { Reference } from '../../../shared/services/referencesService'
import { ActivityType, RiskLevel } from "../../../shared/models/enums";
import { RISK_LEVEL_3_LABEL } from '../../../shared/constants';

const maxEstimatedAmountForLevel0 = 1000000 // 1M

export const eventRisksLevelInitialValues: IEventRisksLevel = {
   estimatedTotalValue: 0,
   countryLevelRisk: '',
   riskLevel: '',
   riskTags: {
      isStrategic: false,
      isHeritage: false,
      isMulti: false
   },
   numberOfPieces: 0
}

export const eventRisksLevelSchema = Yup.object().shape({
   riskLevel: Yup.string().required(),
   estimatedTotalValue : Yup.number().moreThan(0,"Estimated Total Value must be greater than 0").integer("Estimated Total Value must be an integer"),
   countryLevelRisk: Yup.mixed().notRequired(),
   numberOfPieces: Yup.number().required(),
})

export const eventRisksLevelSchemaAreaField = Yup.object().shape({
   riskLevel: Yup.string().required(),
   countryLevelRisk: Yup.string().required('None is only allowed for risk level 0'),
   estimatedTotalValue : Yup.number().moreThan(0,"Estimated Total Value must be greater than 0").integer("Estimated Total Value must be an integer"),
   numberOfPieces: Yup.number().required('Number of pieces is a required field'),
})

const tooltipHeritageMustBeRiskLevel3 = "Risk level 3 must be chosen if open displays / heritage pieces are chosen";

const EventRisksLevel = ({ field, form, ...props }: any) => {
   const [riskLevelList, setRiskLevelList] = useState(props.riskLevel ? props.riskLevel : []);
   const prevEstimatedTotalValueRef = useRef();
   useEffect(() => {
      setRiskLevelList(props.riskLevel.slice())
   }, [props.riskLevel]);

   useEffect(() => {

      if (form.values.riskTags.isHeritage || form.values.riskTags.isStrategic) {
         form.setFieldValue('riskLevel', props.riskLevel.find((ref: Reference) => ref.itemContent === RISK_LEVEL_3_LABEL).id);
      } else {
         form.setFieldValue('riskLevel', '');
      }
   }, [form.values.riskTags.isHeritage, form.values.riskTags.isStrategic])

   useEffect(() => {
      if(prevEstimatedTotalValueRef.current !== form.values.estimatedTotalValue) {
         prevEstimatedTotalValueRef.current = form.values.estimatedTotalValue;
         if(form.values.estimatedTotalValue >= maxEstimatedAmountForLevel0 && props.riskLevel.find((r: Reference) => r.id === RiskLevel.zero)) {
            setRiskLevelList(props.riskLevel.slice(1, props.riskLevel.length));
            if(form.values.riskLevel === RiskLevel.zero) {
               form.setFieldValue('riskLevel', '');
            }
         }
         else {
            setRiskLevelList(props.riskLevelBackup.slice());
         }
      }
   }, [form, props.riskLevel, props.riskLevelBackup]);

   useEffect(() => {
      if (form.values.estimatedTotalValue >= 2) {
         form.setFieldValue("piecesPresentations", true)
         props.updateActivityType(true, ActivityType.Pieces_presentations_on_displays);
      }
   }, [form.values.estimatedTotalValue, props.eventsActivityType]);

   useEffect(() => {
      if (!form.values.numberOfPieces) {
         form.setFieldValue("numberOfPieces", 0)
      }
   }, [form.values.numberOfPieces]);

   return (
      <div>
         <div>
            <label className="double-trailing">(**) This field is mandatory if risk level is greater than 0 (zero)</label>
            <Field id="fieldEstimatedTotalValue" name="estimatedTotalValue" label="Estimated Total Value (*)" component={UiAmountField} type="number" min={0}/>
            <Field id="numberOfPieces" name="numberOfPieces" label="Number of pieces (*)" component={UiNumberField} min={0}/>
            <Field name="countryLevelRisk" label="Area Risk Level (**)" component={UiSelectField} enableValue_None={true} options={props.countryLevelRisk ? props.countryLevelRisk : []} />
            <Field name="riskTags.isStrategic" column="quarter" tooltip={tooltipHeritageMustBeRiskLevel3} label="Open displays" component={UiSwitchField} />
            <Field name="riskTags.isHeritage" column="quarter" tooltip={tooltipHeritageMustBeRiskLevel3} label="Heritage pieces" component={UiSwitchField} />
            <Field name="riskLevel"
                   column="quarter"
                   label="Risk Level (*)"
                   tooltip={tooltipHeritageMustBeRiskLevel3}
                   component={UiSelectField}
                   options={riskLevelList}
                   disabled={form.values.riskTags.isHeritage || form.values.riskTags.isStrategic}
            />
            <p className="message-prevent"
               hidden={!form.values.riskTags.isHeritage && !form.values.riskTags.isStrategic}>
               {tooltipHeritageMustBeRiskLevel3}
            </p>
         </div>
      </div>
   )
}
export default EventRisksLevel;