import React, { useEffect, useState } from "react";
import { CalendarView } from "./components/calendarView/CalendarView";
import { UiListView } from "./components/listview-paggination/UiListView";
import { Container } from "@material-ui/core";
import UiBox from "../shared/components/ui/UiBox";
import { DashboardActions } from "./DashboardActions";
import { DashboardService } from "../shared/services/dashboardService";
import { IModalFilter, IPropsDashboard, IPropsDashboardItem } from "./interface";
import { Reference } from "../shared/services/referencesService";
import { FlowRefService } from "../shared/services/flowRefService";
import { isDateLowerOrEqualTo, isDateSuperiorOrEqualTo, isSameMonth } from "../shared/helpers/HelpersFunc";
import LoadingContext from "../routes/LoadingContext";
import { useRefDataContext } from "../shared/hooks/refDataHook";
import { UserRoles } from "../shared/models/enums";
import { useAuthContext } from "../shared/contexts/AuthContext";
import { roleNotAllowedToSeeCHeader } from "../shared/config/securityConcept";
import { ISort } from "../shared/components/ui/UiTable/types";
import { PAGE_NUMBER_KEY } from "../shared/constants";


export type IStateView = 'list' | 'calendar';

export const Dashboard = (props: IPropsDashboard) => {

   const filterInitialValue: IModalFilter = {
      riskAssessment: false,
      securityConcept: false,
      eventToWatch: false,
      isSummary: false,
      status: [],
      from: "",
      to: "",
      levelRisk: [],
      country: [],
      riskTags: {
         heritage: false,
         public: false,
         strategic: false,
         vip: false
      },
      isNotCancelled: true,
      isNotClosed: true,
      leadBrandName: [],
      minEstimatedValue: undefined,
      maxEstimatedValue: undefined,
      reportedDateFrom: "",
      reportedDateTo: "",
      kpiFilter: ""
   }

   const MODAL_FILTER_KEY = 'modalFilter';
    const [view, setView] = useState<IStateView>('list');
    const [loading, setLoading] = useState<boolean>(true);
    const [errorState, setError] = useState<boolean | string>(false);
    const [searchQueryState, setSearchQueryState] = useState<string>("");
    const [items, setItems] = useState<IPropsDashboardItem[]>([]);
    const [modalFilter, setModalFilter] = useState<IModalFilter>(sessionStorage.getItem(MODAL_FILTER_KEY) ? JSON.parse(sessionStorage.getItem(MODAL_FILTER_KEY)!) : filterInitialValue);
    const [currentMonthClosedEvents,setCurrentMonthClosedEvents] = useState(0)
    const [upcomingEventsLevel1,setUpcomingEventsLevel1] = useState(0)
    const [upcomingEventsLevel2,setUpcomingEventsLevel2] = useState(0)
    const [upcomingEventsLevel3,setUpcomingEventsLevel3] = useState(0)
    const [closedEvents,setClosedEvents] = useState(0)
    const [eventStatusList, setEventStatusList] = useState<Reference[]>([])
    const [keepPagination, setKeepPagination] = useState<boolean>(false)
    const [sort, setSort] = useState<ISort | undefined>(undefined);
    const [filterActivated, setFilterActivated] = useState<string>(sessionStorage.getItem(MODAL_FILTER_KEY) ? JSON.parse(sessionStorage.getItem(MODAL_FILTER_KEY)!).kpiFilter : "");

    const ctx = React.useContext(LoadingContext);
    const { threshold, riskLevel, country : countries, maison } = useRefDataContext();

    const ORDER_KEY = 'dashboardOrder';

   const fetchAll = (isToRefetch: boolean = false) => {

      ctx.startLoading();
      DashboardService.getDashboard().subscribe(response => {
         const dashboard = response;
         setLoading(false);
         setItems(dashboard.dashboardListEntity);
         setUpcomingEventsLevel1(dashboard.upcomingEventsLevel1);
         setUpcomingEventsLevel2(dashboard.upcomingEventsLevel2);
         setUpcomingEventsLevel3(dashboard.upcomingEventsLevel3);
         setClosedEvents(dashboard.closedEvents);
         setCurrentMonthClosedEvents(dashboard.currentMonthClosedEvents)
      }, error => {
         setLoading(false);
         if(error.status === 404){
            setError('something wrong: role undefined (' + error.status + ')');
         }
         else{
            setError('something wrong: ' + error.statusText);
         }

      });
      ctx.stopLoading();
   }

   const setSearchQuery = (searchQuery: string) => {
      sessionStorage.setItem(PAGE_NUMBER_KEY, (1).toString());
      setSearchQueryState(searchQuery)
      sessionStorage.setItem('searchQuery', searchQuery);
      sessionStorage.setItem('view', 'list');
   }
   const getItems = ():IPropsDashboardItem[]  => {
      let itemsFiltered = items;
      if (modalFilter) {
         itemsFiltered = items.filter((item: IPropsDashboardItem) => {
            let tagsMatch = false;
            let countryMatch = false;
            let levelRiskMatch = false;
            let eventStatusMatch = false;
            let fromDateMatch = false;
            let toDateMatch = false;
            let showExclamationPoint = false;
            let securityConcept = false;
            let riskAssessment = false;
            let leadBrandNameMatch = false;
            let reportedDateFromMatch = false;
            let reportedDateToMatch = false;
            let minEstimatedValueMatch = false;
            let maxEstimatedValueMatch = false;
            let notClosedMatch = false;
            let notCancelledMatch = false;
            let kpiMatch = false;

            // TAGS filter
            if ((item.riskTags.isHeritage === modalFilter.riskTags.heritage || modalFilter.riskTags.heritage === false) &&
                (item.riskTags.isStrategic === modalFilter.riskTags.strategic || modalFilter.riskTags.strategic === false)) {
               tagsMatch = true;
            }

            // Country filter
            if (modalFilter.country.length) {
               const countriesFound = modalFilter.country.filter((cnt: any) => cnt.itemContent === item.countriesContent)
               if (countriesFound.length) countryMatch = true
            } else {
               countryMatch = true
            }

            // Area Risk Level filter
            if (modalFilter.levelRisk.length) {
               const levelRiskFound = modalFilter.levelRisk.filter((clr: any) => clr.itemContent === item.riskLevelContent)
               if (levelRiskFound.length) levelRiskMatch = true
            } else {
               levelRiskMatch = true
            }

            //Event Status Filter
            if (modalFilter.status.length) {
               const eventStatusFound = modalFilter.status.filter((status: any) => status.id === item.idFlow)
               if (eventStatusFound.length) eventStatusMatch = true
            } else {
               eventStatusMatch = true
            }

            // maison filter
            if (modalFilter.leadBrandName.length) {
               const leadBrandNameFound = modalFilter.leadBrandName.filter((leadBrandName: any) => leadBrandName.label === item.leadbrandName)
               if (leadBrandNameFound.length) leadBrandNameMatch = true
            } else {
               leadBrandNameMatch = true
            }

            // Not Cancelled filter (status cancelled is set at 10 in db)
            if (item.idFlow !== 10 || !modalFilter.isNotCancelled) {
               notCancelledMatch = true;
            }

            // Not Closed filter (status cancelled is set at 8 in db)
            if (item.idFlow !== 8 || !modalFilter.isNotClosed) {
               notClosedMatch = true;
            }

            // Kpi Filter
            if(modalFilter.kpiFilter !== "") {
               switch (modalFilter.kpiFilter) {
                  case 'closed':
                     const closed = item.idFlow == 8 && (role == UserRoles.hqsecurity || role == UserRoles.localsec || role == UserRoles.maisonsec || role == UserRoles.areasec);
                     const pendingHQ = item.idFlow == 2 && (role == UserRoles.areasec || role == UserRoles.maisonsec || role == UserRoles.localsec);
                     const pendingRegion = item.idFlow == 12 && role == UserRoles.localsec;
                     if (closed || pendingHQ || pendingRegion) {
                        kpiMatch = true;
                     }
                     break;
                  case 'validatedCurrentMonth':
                     if (item.statusModifiedDate && item.statusModifiedDate !== "") {
                        const eventClosedDate = new Date(item.statusModifiedDate)
                        const dateCurrentMonth = new Date();
                        const closed = item.idFlow == 8 && (role == UserRoles.hqsecurity || role == UserRoles.localsec || role == UserRoles.maisonsec || role == UserRoles.areasec);
                        const pendingHQ = item.idFlow == 2 && (role == UserRoles.areasec || role == UserRoles.maisonsec || role == UserRoles.localsec);
                        const pendingRegion = item.idFlow == 12 && role == UserRoles.localsec;
                        if (isSameMonth(dateCurrentMonth, eventClosedDate) && (closed || pendingHQ || pendingRegion)) {
                           kpiMatch = true;
                        }
                     }
                     break;
                  case 'upcomingLevel1':
                     if (item.fromDate && item.fromDate !== "") {
                        const eventFromDate = new Date(item.fromDate);
                        if (isDateSuperiorOrEqualTo(new Date(), eventFromDate) && item.idFlow !== 8 && item.idFlow !== 10 && item.riskLevelContent == 'Risk Level 1') {
                           kpiMatch = true;
                        }
                     }
                     break;
                  case 'upcomingLevel2':
                     if (item.fromDate && item.fromDate !== "") {
                        const eventFromDate = new Date(item.fromDate);
                        if (isDateSuperiorOrEqualTo(new Date(), eventFromDate) && item.idFlow !== 8 && item.idFlow !== 10 && item.riskLevelContent == 'Risk Level 2') {
                           kpiMatch = true;
                        }
                     }
                     break;
                  case 'upcomingLevel3':
                     if (item.fromDate && item.fromDate !== "") {
                        const eventFromDate = new Date(item.fromDate);
                        if (isDateSuperiorOrEqualTo(new Date(), eventFromDate) && item.idFlow !== 8 && item.idFlow !== 10 && item.riskLevelContent == 'Risk Level 3') {
                           kpiMatch = true;
                        }
                     }
                     break;
               }
            } else {
               kpiMatch = true;
            }

            //FromDate Filter
            if (modalFilter.from !== "" && modalFilter.from !== null && item.fromDate) {
               const eventFromDate = new Date(item.fromDate)
               const filterFromDate = new Date(modalFilter.from)
               if (isDateSuperiorOrEqualTo(filterFromDate,eventFromDate)) fromDateMatch = true
            } else {
               fromDateMatch = true
            }

            //ToDate Filter
            if (modalFilter.to !== "" && modalFilter.to !== null && item.toDate) {
               const eventtoDate = new Date(item.toDate)
               const filtertoDate = new Date(modalFilter.to)
               if (isDateLowerOrEqualTo(filtertoDate,eventtoDate)) toDateMatch = true
            }else {
               toDateMatch = true
            }

            //ReportedDateFrom Filter
            if (modalFilter.reportedDateFrom !== "" && modalFilter.reportedDateFrom !== null && item.reportedDate) {
               const eventReportedDate = new Date(item.reportedDate)
               const filterReportedDate = new Date(modalFilter.reportedDateFrom)
               if (isDateSuperiorOrEqualTo(filterReportedDate,eventReportedDate)) reportedDateFromMatch = true
            } else {
               reportedDateFromMatch = true
            }

            //ReportedDateTo Filter
            if (modalFilter.reportedDateTo !== "" && modalFilter.reportedDateTo !== null && item.reportedDate) {
               const eventReportedDate = new Date(item.reportedDate)
               const filterReportedDate = new Date(modalFilter.reportedDateTo)
               if (isDateLowerOrEqualTo(filterReportedDate,eventReportedDate)) reportedDateToMatch = true
            } else {
               reportedDateToMatch = true
            }

            //minEstimatedValue filter
            if (modalFilter.minEstimatedValue && item.minEstimatedValue != null && item.minEstimatedValue >= modalFilter.minEstimatedValue) {
               minEstimatedValueMatch = true;
            } else if (!modalFilter.minEstimatedValue){
               minEstimatedValueMatch = true;
            }

            //maxEstimatedValue filter
            if (modalFilter.maxEstimatedValue && item.maxEstimatedValue != null && item.maxEstimatedValue <= modalFilter.maxEstimatedValue) {
               maxEstimatedValueMatch = true;
            } else if (!modalFilter.maxEstimatedValue){
               maxEstimatedValueMatch= true;
            }

            //Events show Exclamation Point
            if (item.showExclamationPoint === modalFilter.eventToWatch || modalFilter.eventToWatch === false) {
               showExclamationPoint = true;
            }
            //Events has security concept
            if (item.hasConceptAccessDoc === modalFilter.securityConcept || modalFilter.securityConcept === false) {
               securityConcept = true;
            }
            //Events has Report Assessment
            if (item.hasReportAssessment === modalFilter.riskAssessment || modalFilter.riskAssessment === false) {
               riskAssessment = true;
            }

            return (kpiMatch && leadBrandNameMatch && maxEstimatedValueMatch && minEstimatedValueMatch && reportedDateFromMatch && reportedDateToMatch && notCancelledMatch && notClosedMatch && tagsMatch && countryMatch && levelRiskMatch && eventStatusMatch && fromDateMatch && toDateMatch && showExclamationPoint && securityConcept && riskAssessment)
         })
      }

      if (searchQueryState) {
         itemsFiltered = itemsFiltered.filter((item: IPropsDashboardItem) => {
            const eventName = item.eventName ? item.eventName : ""
            const countriesContent = item.countriesContent ? item.countriesContent : ""
            const leadbrandName = item.leadbrandName ? item.leadbrandName : ""
            const searchField = eventName + countriesContent + leadbrandName;
            if (searchField.toLowerCase().includes(searchQueryState.toLowerCase())) {
               return true;
            }

            return false;
         })
      }
      return itemsFiltered.map(item => {
         item.fetch = fetchAll
         return item;
      });
   }

   const mapItemsToItemsCalendar = (itemsFiltered : IPropsDashboardItem[]) : any[] => {
      return itemsFiltered.map((item : IPropsDashboardItem) => {
         return {
            start: item.fromDate,
            end: item.toDate ? item.toDate : item.fromDate,
            title: item.eventName,
            resource: {
               id : item.id,
               risks: item.riskTags,
               country: item.countriesContent,
               maison: item.leadbrandName,
               amount: item.estimatedTotalValue,
               status: item.status,
               showExclamationPoint: item.showExclamationPoint,
               riskLevelContent: item.riskLevelContent,
               item: item
            }
         }
      })
   }

   const saveAndLoadFilterStatus = () => {
      const storedSearchQuery = sessionStorage.getItem('searchQuery')
      if (storedSearchQuery !== null && storedSearchQuery !== '' && storedSearchQuery !== searchQueryState) {
         setSearchQueryState(storedSearchQuery);
      }
      if(modalFilter) {
         sessionStorage.setItem(MODAL_FILTER_KEY, JSON.stringify(modalFilter));
         return;
      }
      const statusStorage = sessionStorage.getItem(MODAL_FILTER_KEY);

      if(statusStorage !== null){
         const storedFilter:IModalFilter =  JSON.parse(statusStorage);
         setModalFilter(storedFilter);
         setFilterActivated(storedFilter.kpiFilter)
      } else {
         setModalFilter(filterInitialValue);
      }

   }

   const applyModalFilter = (modalFilterToApply: IModalFilter) => {
      if(modalFilterToApply === undefined){
         sessionStorage.removeItem(MODAL_FILTER_KEY);
      }
      sessionStorage.setItem(PAGE_NUMBER_KEY, (1).toString());
      setModalFilter(modalFilterToApply);

   }

   useEffect(() => {
      const viewSaved = sessionStorage.getItem('view') as IStateView;
      if(viewSaved && viewSaved.length) setView(viewSaved);

      fetchAll();

      const eventStatusSubscription = FlowRefService.getAllEventStatus().subscribe(
          (eventStatusListRef) => setEventStatusList(eventStatusListRef),
          () => {})


      return () => {
         eventStatusSubscription.unsubscribe();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const {role} = useAuthContext();
   const getRowClasses = (item: IPropsDashboardItem) => {
      if((role === UserRoles.hqsecurity || role === UserRoles.director) && item.estimatedTotalValue &&  item.estimatedTotalValue > threshold.value.thresholdValue){
         return "dashboard-table__row--danger"
      }
      return ""
   }

   const isRoleCannotSeeColumn = () => {
      return (
          role !== undefined &&
          roleNotAllowedToSeeCHeader.includes(role)
      );
   }

   const onOrdering = (item:any) => {
      sessionStorage.setItem(ORDER_KEY, JSON.stringify(item));
   }

   const initSorting = () => {
      const jsonSortInStorage = sessionStorage.getItem(ORDER_KEY)

      if (!jsonSortInStorage) {
         return;
      }

      const sortInStorage = JSON.parse(jsonSortInStorage);
      if (sort?.order === sortInStorage.order) {
         return;
      }

      setSort(sortInStorage);

   }

   const kpiFilter = (filter:string) => {
      let newFilters = filterInitialValue;
      newFilters.kpiFilter = filter;
      newFilters.isNotClosed = false;
      sessionStorage.setItem(PAGE_NUMBER_KEY, (1).toString());
      setFilterActivated(filter);
      setModalFilter(newFilters)
   }

   const deleteKpiFilter = () => {
      setFilterActivated("")
      let newFilters = modalFilter;
      newFilters!.kpiFilter = "";
      newFilters!.isNotClosed = true;
      sessionStorage.setItem(PAGE_NUMBER_KEY, (1).toString());
      setModalFilter(newFilters)
   }

   saveAndLoadFilterStatus();
   initSorting();

   return (
       <React.Fragment>
          <Container maxWidth="xl">
             <div className="dashboard-head">
                <h1>Dashboard</h1>
                <div className="dashboard-head__number">
                   <div>
                      <p>Validated events in total</p>
                      <span style={{ cursor: 'pointer'}} onClick={() => kpiFilter("closed")}>{closedEvents}</span>
                   </div>
                   <div>
                      <p>Validated events in the current month</p>
                      <span style={{ cursor: 'pointer' }} onClick={() => kpiFilter("validatedCurrentMonth")}>{currentMonthClosedEvents}</span>
                   </div>
                   <div>
                      <p>Upcoming events Level 1</p>
                      <span style={{ cursor: 'pointer' }} onClick={() => kpiFilter("upcomingLevel1")}>{upcomingEventsLevel1}</span>
                   </div>
                   <div>
                      <p>Upcoming events Level 2</p>
                      <span style={{ cursor: 'pointer' }} onClick={() => kpiFilter("upcomingLevel2")}>{upcomingEventsLevel2}</span>
                   </div>
                   <div>
                      <p>Upcoming events Level 3</p>
                      <span style={{ cursor: 'pointer' }} onClick={() => kpiFilter("upcomingLevel3")}>{upcomingEventsLevel3}</span>
                   </div>
                </div>
             </div>
             <UiBox
                 title="Upcoming Events"
                 name="dashboard"
                 filterActivated={filterActivated}
                 deleteKpiFilter={deleteKpiFilter}
                 actions={
                    <DashboardActions
                        setSearchQuery={setSearchQuery}
                        setModalFilter={applyModalFilter}
                        modalFilter={modalFilter}
                        setView={setView}
                        view={view}
                        searchQuery={searchQueryState}
                        coutriesRef={countries.items}
                        riskLevelRef={riskLevel.items}
                        eventStatusListRef={eventStatusList}
                        maisonRef={maison.items}
                    />
                 }
             >
                {
                   view === 'list' ?
                       <UiListView
                           rowClasses={getRowClasses}
                           items={getItems()}
                           loading={loading}
                           error={errorState}
                           isVisible={isRoleCannotSeeColumn()}
                           keepPagination={keepPagination}
                           sortColumnHandler={onOrdering}
                           sort={sort}
                       />
                       :
                       <CalendarView events={mapItemsToItemsCalendar(getItems())} onClickBtn={() => fetchAll(true)}/>
                }
             </UiBox>
          </Container>
       </React.Fragment>
   );
}

